
export default {
  name: 'Expander',
  props: {
    minHeight: {
      type: Number,
      default: 50,
    },
    showText: {
      type: String,
      default: 'Показать',
    },
    hideText: {
      type: String,
      default: 'Скрыть',
    },
    isShadow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
      contentHeight: `${this.minHeight}px`,
    };
  },
  watch: {
    isExpanded(value) {
      const { content } = this.$refs;
      this.contentHeight = value ? `${content ? `${content.scrollHeight}px` : 'auto'}` : `${this.minHeight}px`;
    },
  },
};
