
import {prepareBrand} from '~/plugins/helpers/catalog';
import PopularBrandsList from '~/components/elements/PopularBrands/List';
import Expander from '~/components/elements/Expander';

export default {
  name: 'PopularBrands',
  components: {PopularBrandsList,Expander},
  props: {
    brands: {
      type: Array,
      required: true,
    },
    visibleBrandsNum: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    list() {
      const items = {
        image: [],
        text: [],
        hidden: [],
      }

      this.brands.forEach(item => {
        const brand = prepareBrand(item)
        if (!item.image) {
          items.text.push(brand)
        } else {
          items.image.length >= this.visibleBrandsNum ? items.hidden.push(brand) : items.image.push(brand)
        }
      })

      return items
    },
  },
}
