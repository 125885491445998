
import PopularBrandsItem from '~/components/elements/PopularBrands/Item';
export default {
  name: 'PopularBrandsList',
  components: {PopularBrandsItem},
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
}
